import React from "react";
import { Card, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Navbar from "../../layouts/Navbar";
import Sidebar from "../../layouts/Sidebar";
import {
  getProductList,
  S3_root,
  createProduct,
  getProductUnits,
  getProductById,
  changePage,
  updateProduct,
  getProductHistory,
  effectiveDateFormat,
  displayCurrency,
  clearStockProduct,
  changeStockName,
  getPoInwardList,
  getCustomersList,
  getVendorList,
  changeProjectClassName,
  getProductDownloadCsv,
  exportToJson,
  twoDigitsValue,
  clearCsvData
} from "../../actions";
import { connect } from "react-redux";
import Filter from "../../assets/images/filter.svg";
import Spinner from "../../Components/loader";
import Orange from "../../assets/images/red1.svg";
import Search from "../../assets/images/search.svg";
import profile from "../../assets/images/we.svg";
import ProductForm from "./ProductForm";
import Pagination from "../../Components/Pagination";
import { toast } from "react-toastify";
import { SubmissionError } from "redux-form";
import ProductFilter from "./ProductFilter";
import parse from 'html-react-parser';
import AddStock from "./AddStock";
import BulkUpload from "../inwardoutward/inward/bulkUpload";
import Art from "../../assets/images/art.svg";
import Load from "../../assets/images/rou.gif";
import Bulk from "./Bulk";

const role = localStorage.getItem('userRole');
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all_products: [],
      all_units: [],
      show: false,
      showStock: false,
      selectedFile: "",
      isFilePicked: false,
      success_msg: "",
      product_history: [],
      allData: [],
      sortType: "asc",
      price: "",
      date: "",
      productName: "",
      searchKey: "",
      articleId: "",
      sortField: "",
      productId: null,
      filterData: {
        searchKey: null,
        pageNo: 0,
        sortType: "ASC",
        sortField: "p.product_id",
        fromDate: null,
        toDate: null,
        sizeOfPage: 10
      },
      pageNo: 0,
      sort: {},
      empty: "",
      StockPopup: false,
      stockData: [],
      bulkUploadPopup: false,
      bulkExcelPopup: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.stockhandleClose = this.stockhandleClose.bind(this);
    this.stockhandleShow = this.stockhandleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.imageHandler = this.imageHandler.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleinput = this.handleinput.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.getPriceData = this.getPriceData.bind(this);
    this.calHistory = this.calHistory.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.getAllProduct = this.getAllProduct.bind(this);
    this.bulkUploadShow = this.bulkUploadShow.bind(this);
    this.bulkUploadClose = this.bulkUploadClose.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
    this.uploadShow = this.uploadShow.bind(this);
    this.uploadClose = this.uploadClose.bind(this);
  }


  calHistory() {
    this.props.dispatch(getProductHistory(this.state.productId));
  }
  getAllProduct() {
    const { filterData } = this.state;
    this.props.dispatch(getProductList(filterData));
  }
  getPriceData(val) {
    this.setState({
      price: val.newPrice,
      date: effectiveDateFormat(val.effectiveDate)
    })
    this.props.dispatch(getProductHistory(this.state.productId));
    this.props.dispatch(getProductById(this.state.productId));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.products_list !== this.props.products_list) {
      const newPro = this.props?.products_list?.filter((item, i) => i < 10);
      this.setState({
        all_products: newPro,
      });
    }
    if (prevProps.product_units !== this.props.product_units) {
      this.setState({ all_units: this.props.product_units });
    }
    if (prevProps.product_history !== this.props.product_history) {
      this.setState({ product_history: this.props.product_history });
    }
    if (prevProps.allData !== this.props.allData) {
      this.setState({ allData: this.props.allData });
    }
    if (prevProps.success_msg !== this.props.success_msg) {
      this.setState({
        success_msg: this.props.success_msg,
      });
      if (this.props.success_msg?.status === 200) {
        this.setState({
          show: false,
          showStock: false,
          isFilePicked: false,
          selectedFile: "",
        })
        toast(this.props.success_msg?.data);
        this.getAllProduct();
      } else {
        toast(this.props.success_msg?.data);
      }
    } if (this.props.csvData?.length !== 0) {
      exportToJson(this.props.csvData, "Product Report");
      this.props.dispatch(clearCsvData());
    }
  }
  handleSubmit(values) {
    if (!values.productName) {
      throw new SubmissionError({
        productName: 'Please fill the field *',
      })
    }
    var formData = new FormData();
    if (this.state.selectedFile) {
      formData.append("productImage", this.state.selectedFile, this.state.selectedFile.name);
    }
    if (this.state.price !== "" || this.state.date !== "") {
      formData.append("newPrice", this.state.price);
      formData.append("effectiveFromDate", this.state.date);
    }
    if (this.props.page === "edit") {
      if (values.productName !== this.state.productName) {
        formData.append("productName", values.productName);
      } if (values.articleId !== this.state.articleId) {
        formData.append("articleId", values.articleId);
      }
      let status = values.status === "Active" ? true : false;
      formData.append("active", status);
      formData.append("thresholdValue", values.thresholdValue);
      formData.append("unitId", values.unitId);
      formData.append("productId", values.productId);
      this.props.dispatch(updateProduct(formData));
    }
    else {
      if (values.articleId !== undefined) {
        formData.append("articleId", values.articleId);
      } else {
        formData.append("articleId", "");
      }
      formData.append("thresholdValue", values.thresholdValue);
      formData.append("productName", values.productName);
      formData.append("unitId", values.unitId);
      formData.append("price", values.newPrice);
      this.props.dispatch(createProduct(formData));
    }
  }
  handleShow() {
    this.props.dispatch(changePage());
    this.setState({
      show: true,
      empty: "create"
    });
  }
  handleClose() {
    this.setState({
      show: false,
      selectedFile: "",
      isFilePicked: false,
    });
  }
  stockhandleShow() {
    this.props.dispatch(clearStockProduct());
    this.props.dispatch(getCustomersList(0));
    this.props.dispatch(getVendorList());
    this.setState({
      showStock: true,
    });
  }
  stockhandleClose() {
    this.setState({
      showStock: false,
    });
  }
  bulkUploadShow() {
    this.props.dispatch(getPoInwardList());
    this.setState({ bulkUploadPopup: true, showStock: false });
  }
  bulkUploadClose() {
    this.setState({ bulkUploadPopup: false });
  }
  imageHandler = (event) => {
    const imageFile = event.target.files[0];
    if (!imageFile?.name?.match(/\.(jpg|jpeg|png)$/)) {
      toast("Please select valid image.");
      return false;
    } else {
      this.setState({
        selectedFile: imageFile,
        isFilePicked: true,
      })
    }
  }
  sortHandler(sortField) {
    const { filterData } = this.state;
    let data = { ...filterData };
    data.sortField = sortField;
    data.sortType = filterData.sortType === "ASC" ? "DESC" : "ASC";
    this.setState({
      filterData: data
    })
    this.props.dispatch(getProductList(data));
  }
  searchHandler(e) {
    const { filterData } = this.state;
    let data = { ...filterData };
    data.searchKey = e.target.value;
    data.pageNo = 0;
    this.setState({
      filterData: data
    })
    this.props.dispatch(getProductList(data));
  }
  filterSubmit(filterData) {
    let data = { ...this.state.filterData };
    data.fromDate = filterData.fromDate;
    data.toDate = filterData.toDate;
    this.setState({
      filterData: data
    })
    this.props.dispatch(getProductList(data));
  }
  resetHandler() {
    let data = { ...this.state.filterData };
    data.fromDate = null;
    data.toDate = null;
    this.setState({
      filterData: data
    })
    this.props.dispatch(getProductList(data));
  }
  onChange(pageNo) {
    let data = { ...this.state.filterData };
    data.pageNo = pageNo;
    this.setState({
      filterData: data
    })
    this.props.dispatch(getProductList(data));
  }
  handleinput(data) {
    this.setState({
      StockPopup: true,
      stockData: data?.projectProductStockResDtoList,
    });
  }
  handleEdit(product) {
    this.setState({
      show: true,
      date: effectiveDateFormat(product.effectiveOnDate),
      price: product.currentPrice,
      productName: product.productName,
      articleId: product.articleId,
      productId: product.productId,
      empty: "edit"
    });
    this.props.dispatch(getProductById(product.productId));
    this.props.dispatch(getProductHistory(product.productId));
  }
  downloadCsv() {
    this.props.dispatch(getProductDownloadCsv());
  }
  componentDidMount() {
    const { filterData } = this.state;
    this.props.dispatch(getProductList(filterData));
    this.props.dispatch(getProductUnits());
  }

  uploadShow() {
    this.setState({ bulkExcelPopup: true });
  }
  uploadClose() {
    this.setState({ bulkExcelPopup: false });
    const { filterData } = this.state;
    this.props.dispatch(getProductList(filterData));
  }


  render() {
    const { all_products,
      all_units,
      selectedFile,
      isFilePicked,
      show,
      product_history,
      allData,
      showStock,
      empty,
      onHide,
      stockData,
      bulkUploadPopup,
      bulkExcelPopup
    } = this.state;

    let data = {
      totalElements: allData.totalElements,
      totalPages: allData.totalPages,
      offset: allData?.pageable?.offset,
      numberOfElements: allData.numberOfElements,
      num: (allData?.number) + 1
    }
    return (
      <div>
        <Navbar />
        <div className="dashboard-page">
          <div className="dashboard-sidebar">
            <Sidebar />
          </div>
          <div className="dashboard-main">
            <Card className="staff-page">
              <div className="page-head-split">
                <div className="head-colums">
                  <div className="head-part-tabs">
                    <img src={Orange} alt="..." className="head-imgs" />
                    <h5> Product Configuration</h5>
                  </div>
                  {this.props.load ?
                    <button className="download_btn" >
                      <img src={Load} alt=".. /" width="22px" /> Stock
                    </button>

                    : <button className="download_btn" onClick={this.downloadCsv}>
                      <img src={Art} alt=".." /> Stock
                    </button>}
                  {role === "admin" ?
                    <Bulk
                      uploadShow={this.uploadShow}
                      show={bulkExcelPopup}
                      uploadClose={this.uploadClose} /> : null}

                  <div className="customer-search">
                    <span><img src={Search} alt=".." className="search-img" /></span>
                    <Form.Control type="search" placeholder="Search" onChange={this.searchHandler} className="main-search" />
                  </div>
                  <ProductFilter onSubmit={this.filterSubmit} reset={this.resetHandler} />
                  <AddStock
                    show={showStock}
                    handleShow={this.stockhandleShow}
                    onHide={this.stockhandleClose}
                    empty={empty}
                    bulkPopup={this.bulkUploadShow}
                    bulkShow={bulkUploadPopup}
                    bulkPopupHide={this.bulkUploadClose}
                    listData={() => console.log("")}
                  />
                  <ProductForm
                    str="add"
                    show={show}
                    handleShow={this.handleShow}
                    onHide={this.handleClose}
                    onSubmit={this.handleSubmit}
                    units={all_units}
                    imgHandler={this.imageHandler}
                    selectedFile={selectedFile}
                    filePick={isFilePicked}
                    history={product_history}
                    getPriceData={this.getPriceData}
                    productId={this.state.productId}
                    getHistory={this.calHistory}
                    empty={empty}
                  />
                </div>
              </div>
              <div className="table-class">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">
                        Article ID
                      </th>
                      <th scope="col">Image</th>
                      <th scope="col">
                        Product Name <img src={Filter} alt="..." onClick={(e) => this.sortHandler("p.product_name")} />
                      </th>
                      <th scope="col" className="txt-center">
                        Unit <img src={Filter} alt="..." onClick={(e) => this.sortHandler("u.unit_name")} />
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        Old Price
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        Current Price <img src={Filter} alt="..." onClick={(e) => this.sortHandler("p.current_price")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Threshold Value <img src={Filter} alt="..." onClick={(e) => this.sortHandler("p.active")} />
                      </th>
                      <th scope="col" style={{ textAlign: "center" }}>
                        Stock <img src={Filter} alt="..." onClick={(e) => this.sortHandler("total_stock")} />
                      </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {all_products && all_products.length > 0 ? (
                      all_products.map((item, i) => {
                        const imgUrl = item.imageUrl ?? item.imageUrl != null ? S3_root + item.imageUrl : profile;
                        return <tr key={i} style={{ cursor: "pointer" }} className={item?.priceUpdatedLastSixMonths === true ? "shade" : ""} >
                          <td onClick={() => this.handleEdit(item)}>{item.articleId}</td>
                          <td onClick={() => this.handleEdit(item)}>
                            <img src={imgUrl} alt="..." width="30px" className="pro-imgs" />
                          </td>
                          <td className="inward_new_size" onClick={() => this.handleEdit(item)}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.productName || "-")}</Tooltip>}>
                              <span className="d-inline-block">
                                {parse(item.productName || "-")}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td onClick={() => this.handleEdit(item)} className="txt-center">{item.unitName}</td>
                          <td className="new_price_td" onClick={() => this.handleEdit(item)}>{displayCurrency(item?.oldPrice)}</td>
                          <td className="new_price_td" onClick={() => this.handleEdit(item)}>{displayCurrency(item?.currentPrice)}</td>

                          <td style={{ textAlign: "center" }} onClick={() => this.handleEdit(item)}>
                            {item.thresholdValue}
                          </td>
                          <td style={{ textAlign: "center" }} onClick={() => this.handleinput(item)}>
                            <button className={changeStockName(item.totalStock)}>{twoDigitsValue(item?.totalStock || 0)}</button>
                          </td>
                          <td>
                            <button className="Edit-staff" onClick={() => this.handleEdit(item)}>
                              Edit
                            </button>
                          </td>
                        </tr>
                      })) : !this.props.loading ?
                      <h1 className="empty-data">No Records Found</h1> :
                      <Spinner />
                    }
                  </tbody>
                </table>
              </div>
            </Card>
            <Pagination data={data} onChange={this.onChange} />
          </div>
        </div >
        <Modal
          show={this.state.StockPopup}
          onHide={() => {
            this.setState({
              StockPopup: false
            });
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <div className="modal-staff" >
            <div className="head">
              <div>
                <i class="fas fa-arrow-left" onClick={() => {
                  this.setState({
                    StockPopup: false
                  });
                }}></i>
                <h4>PROJECTS</h4>
              </div>
            </div>
            <div className="" style={{ margin: "20px" }}>
              <table class="table table-borderless" >
                <thead>
                  <tr>
                    <th scope="col" style={{ textAlign: "center" }}>
                      PO Number
                    </th>
                    <th scope="col">Project Name</th>
                    <th scope="col">
                      Customer Name
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>Required Qty</th>
                    <th scope="col" style={{ textAlign: "center" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {stockData && stockData.length > 0 ? (
                    stockData.map((item) => {
                      return <tr key={item.purchaseOrderId ?? "-"} >
                        <td style={{ textAlign: "center" }}>{item.purchaseOrderId ?? "-"}</td>
                        <td className="inward_new_size">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.projectName || "-")}</Tooltip>}>
                            <span className="d-inline-block">
                              {parse(item.projectName || "-")}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td className="inward_new_size">
                          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"> {parse(item.customerName || "-")}</Tooltip>}>
                            <span className="d-inline-block">
                              {parse(item.customerName || "-")}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td style={{ textAlign: "center" }}>{item.requiredQty ?? "-"}</td>
                        <td style={{ textAlign: "center" }}>
                          <button className={changeProjectClassName(item.status)}>
                            {item.status}
                          </button>
                        </td>
                      </tr>
                    })) :
                    < h1 className="empty-data-set">No Records Found</h1>

                  }
                </tbody>
              </table>
            </div>
          </div>
        </Modal >
        <div style={{ display: "none" }}>
          <BulkUpload
            handleShow={this.bulkUploadShow}
            show={bulkUploadPopup}
            onHide={this.bulkUploadClose}
          />
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.product.loading,
    products_list: state.product.products_list,
    product_units: state.product.product_units,
    success_msg: state.product.success_msg,
    product_history: state.product.product_history,
    page: state.product.page,
    allData: state.product.allData,
    csvData: state.product.csvData,
    load: state.product.load
  };
};

export default connect(mapStateToProps)(Product);

